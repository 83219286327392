@import 'default.them';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
    padding-left: 24px;
  }
}

@import 'ngx-toastr/toastr';
@import '../../../node_modules/tippy.js/dist/tippy.css';

@import 'beauty-scroll';

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
body {
  @apply text-txt overflow-hidden;
  margin: 0;
  font-family: 'Nunito Sans';
}

// Material Dialog Styles
.normal-modal .mat-mdc-dialog-container {
  padding: 0 !important;
  max-height: 90vh !important;
}

@media (max-width: 599px) {
  .cdk-overlay-pane.mat-mdc-dialog-panel {
    max-width: 90vw !important;
  }
}

.mat-mdc-dialog-surface {
  @extend .beautyScroll;
  border-radius: 4px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.heat-map-modal .mat-mdc-dialog-container {
  padding: 0 !important;
  max-height: 95vh !important;
  width: 80vw !important;
}

.mat-mdc-menu-panel {
  max-width: none !important;
  border-radius: 4px !important;
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  print-color-adjust: exact !important; /*Firefox*/
}

.ngx-pagination {
  margin-bottom: 0 !important;
}

// Border classes
.inside-border {
  box-shadow: 0 0 0 1px inset;
}

// Date range picker style
.mat-datepicker-content-container {
  @screen md {
    flex-direction: row !important;
  }
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;

  .mat-calendar-body-label {
    @apply text-white h-0 overflow-hidden;
    padding: 0 !important;
  }
}

.mat-calendar-header .mat-calendar-controls {
  @apply mt-0 justify-between;

  .mat-calendar-spacer {
    @apply hidden;
  }

  .mat-calendar-period-button {
    @apply order-2;
  }

  .mat-calendar-previous-button {
    @apply order-1;
  }

  .mat-calendar-next-button {
    @apply order-3;
  }
}

.lla-filter-menu {
  @apply mt-sm mb-lg max-h-[90vh] flex flex-col;

  .mat-mdc-menu-content {
    @extend .beautyScroll;
    @apply py-0 flex flex-col overflow-auto;
  }
}

.lla-filter-menu-full {
  @apply md:mt-sm md:mb-lg h-[100dvh] w-[100vw] md:h-auto md:w-auto md:max-h-[70vh] flex flex-col;

  .mat-mdc-menu-content {
    @extend .beautyScroll;
    @apply py-0 flex flex-col overflow-auto;
  }
}

// Tippy Tooltip Class
.tippy-box {
  @apply bg-contrast p-sm shadow-e1 rounded-sm text-txt;

  @apply max-w-fit #{!important};

  .tippy-content {
    @apply p-0;
  }
}

.copy-tooltip-cell {
  .mat-mdc-tooltip-surface {
    @apply bg-txt text-white;
  }
}
